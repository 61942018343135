import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout'

const IrPost = ({ data }) => {
    return (
        <Layout>
            <div className="containerWrapper">
                <div className="wrapperPage">
                    <>
                    <h1>{data.releases.Title}</h1>
                    <div className="pressPost" dangerouslySetInnerHTML={{ __html: data.releases.HtmlBody }}></div>
                    </>
                </div>
            </div>
        </Layout>
    );
};


export const query = graphql`
    query($slug: String) {
        releases(EncryptedId: { eq: $slug }) {
            Title
            HtmlBody
        }
    }
`;


export default IrPost;